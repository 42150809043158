<template>
  <div class="home">
    <div
      class="tds-desktop-header tds-bg_white slds-p-vertical_small"
      style="border-bottom: 3px solid rgb(0, 112, 210); width: 100%;"
    >
      <div
        class="slds-grid slds-grid_align-spread slds-container_x-large slds-container_center slds-p-horizontal_small"
      >
        <div class="slds-grid slds-grow">
          <div
            class="slds-grid slds-grid_vertical-align-center slds-shrink-none"
          >
            <!-- <a
              class="slds-show slds-m-right_large"
              target="_blank"
              href="https://www.salesforce.com/form/event/trailblazers-innovate/"
            > -->
              <img
                alt="Logo"
                class="logo"
                width="90"
                height="auto"
                src="@/assets/images/trailhead_logo.png"
            />
            <!-- </a> -->
          </div>
          <div class="slds-grow slds-grid slds-grid_vertical-align-end">
            <div class="tds-context-bar" style="width: 100%;"></div>
          </div>
        </div>
        <div class="slds-m-left_medium slds-grid">
          <div class="slds-grid slds-grid_vertical-align-center">
            <div class="slds-grid">
              <div class="slds-m-left_x-small">
                <!-- <a
                  class="slds-button slds-button_brand tds-button_brand"
                  type="button"
                  target="_blank"
                  href="https://www.salesforce.com/form/event/trailblazers-innovate/"
                >
                  Register Now
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <header>

      <section class="container pt-5">
      <div class="row" style="background-color: rgba(255,255,255,0.9); padding: 25px 0; border-radius: 25px;">
        <div class="col-md-7 offset-1">
          <h1>Trailhead Taught Me</h1>


        <p class="tds-text-size_5">
          Trailhead empowers everyone to learn in-demand skills, earn resume-worthy credentials, and connect to opportunity - from anywhere.
        </p>

        <p class="tds-text-size_5">
          Share what you’ve learned on Trailhead and how you’ve implemented it by personalizing your own social card. Use <strong>#TrailheadTaughtMe</strong> and showcase how you’ve put your learning into action.
        </p>
        </div>
        <div class="col-md-4 d-block mx-auto text-center">
            <img class="img-fluid" @click="$bvModal.show('bv-modal-example')" style="mix-blend-mode: multiply; cursor: pointer;" src="@/assets/images/video_player.png" alt="">
            <p><a class="" @click="$bvModal.show('bv-modal-example')" style="font-size: 16px;">View demo video</a></p>
            <!-- <b-button id="show-btn" @click="$bvModal.show('bv-modal-example')">Open Modal</b-button> -->

            <b-modal id="bv-modal-example" size="xl" hide-footer>
              <!-- <template #modal-title>
                Using <code>$bvModal</code> Methods
              </template> -->
              <div class="d-block text-center">
                <b-embed type="video" aspect="16by9" controls>
                  <!-- <source src="dev-stories.webm" type="video/webm"> -->
                  <source src="https://res.cloudinary.com/da84jf4vw/video/upload/v1618245525/trailhead/trailhead_demo_15_yjcjaa.mp4" type="video/mp4">
                </b-embed>
              </div>
              <!-- <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">Close Me</b-button> -->
            </b-modal>
        </div>
      </div>
      </section>

      <!-- <section
        class="slds-grid slds-grid_vertical slds-grid_vertical-align-center"
      >
        <h2
          class="slds-grid slds-grid_vertical slds-text-align_center slds-m-top_xx-large slds-m-bottom_medium"
        >
          <span>Create your own</span>
          <span>#TRAILHEADTAUGHTME</span>
          <span>Snapshot</span>
        </h2>
        <p class="slds-text-align_center tds-text-size_7">
          Trailblazers deliver innovation in so many ways! We encourage every
          Trailblazer to share your innovation pride and inspiration. Create a
          custom snapshot, write a post to inspire others, and share on social
          with hashtag <strong>#TrailblazersTaughtMe</strong>.
        </p>
            <img class="img-fluid" style="mix-blend-mode: multiply;" src="@/assets/images/video_player.png" alt="">
        <div class="header-brush"></div>
      </section> -->

    </header>
    <div data-section="app">
      <section>
        <div>
          <img class="astro-camera" src="@/assets/images/astro-camera.svg" />
        </div>
        <photo-maker></photo-maker>
        <!-- <div id="oldapp"></div> -->
      </section>
    </div>
    <div data-section="trailhead">
      <section
        class="slds-grid slds-grid_vertical slds-grid_vertical-align-center slds-p-vertical_xx-large "
      >
        <!-- <img alt="Trailhead" src="/assets/images/lwc.svg" /> -->
        <h3>
          We love to hear your stories.
        </h3>
        <p class="slds-m-bottom_medium slds-text-align_center">
          Share your customized card with us on social using #TrailheadTaughtMe
        </p>
        <a
          class="slds-button slds-button_brand tds-button_brand tds-button_xlarge"
          target="_blank"
          href="https://www.twitter.com"
          >Post Now!</a
        >
      </section>
    </div>
    <div
      class="tds-bg_white slds-grid slds-wrap slds-grid_align-spread slds-container_x-large slds-container_center slds-grid_vertical-align-center slds-p-vertical_medium slds-p-horizontal_large"
    >
      <div class="slds-size_1-of-1 slds-large-size_5-of-6">
        <div class="slds-grid slds-grid_vertical-align-start">
          <span class="slds-shrink-none"></span>
          <svg
            aria-hidden="true"
            class="slds-icon slds-icon-text-default"
            style="fill: rgb(0, 161, 224);"
          >
            <!-- <use
              xlink:href="@/assets/icons/utility-sprite/svg/symbols.svg#salesforce1"
            ></use> -->
          </svg>
          <div class="slds-grow tds-text-size_3">
            <div class="slds-grid slds-wrap">
              <div
                class="slds-text-color_weak slds-m-horizontal_small slds-m-vertical_x-small"
              >
                &copy; 2021 salesforce.com, inc. All rights reserved.
              </div>
              <a
                href="https://www.salesforce.com/company/privacy/"
                target="_blank"
                class="slds-show slds-text-color_weak slds-m-horizontal_small slds-m-vertical_x-small"
                >Privacy Statement</a
              >
              <a
                href="https://www.salesforce.com/company/security.jsp"
                target="_blank"
                class="slds-show slds-text-color_weak slds-m-horizontal_small slds-m-vertical_x-small"
              >
                Security Statement</a
              >
              <a
                href="https://trailblazer.me/terms"
                target="_blank"
                class="slds-show slds-text-color_weak slds-m-horizontal_small slds-m-vertical_x-small"
              >
                Terms of Use</a
              >
              <a
                href="https://www.salesforce.com/company/"
                target="_blank"
                class="slds-show slds-text-color_weak slds-m-horizontal_small slds-m-vertical_x-small"
              >
                About Us</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import PhotoMaker from '../components/PhotoMaker.vue';

export default {
  name: "Home",
  components: {
    PhotoMaker
  },
  // data() {
  // },
  methods: {
  }
};
</script>

<style lang="scss">

</style>
