<template>
  <div>
    <!-- <div id="mobile-container">
    <p>This site works best with screens larger than 768px. Try in landscape mode or visit a desktop browser.</p>
  </div> -->
    <div id="photo-container">
      <div
        v-resize.initial="onResize"
        ref="scaleablewrapper"
        id="scaleable-wrapper"
        class="scaleable-wrapper"
      >
        <div
          id="preview"
          :style="inlineStyle"
          style=""
          class="pb-3 preview"
          ref="preview"
        >
          <div>
            <p style="padding: 0px 25px 0 20px; max-width: 475px; min-width: 475px; overflow: hidden;">
              <span v-if="taught || empower">&ldquo;</span
              ><span v-if="taught"
                >Trailhead taught me <strong>{{ taught }}</strong></span
              >
              <span v-if="empower"
                >, empowering me to <strong>{{ empower }}</strong
                >.</span
              ><span v-if="taught || empower">&rdquo;</span>
            </p>
            <p
              style="
                float: right;
                padding-right: 30px;
                text-align: right;
                line-height: 28px;
              "
            >
              <span class="trailhead">{{ name }}</span
              ><br />
              <span class="light">{{ title }}</span
              ><span v-if="company" class="light">, {{ company }}</span
              ><br />
              <span class="light">{{ badge_title }}</span>
            </p>
          </div>
          <img v-if="badge_url" :src="badge_url" class="badges" alt="" />
          <img
            v-if="img_url"
            class="photo_img"
            :src="img_url"
            :style="badge_url ? 'margin-left: -43px !important;' : ''"
            alt=""
          />
          <img
            v-if="selected_character"
            :class="'character_img ' + selected_character.class"
            :style="badge_url ? 'margin-left: -30px !important;' : ''"
            :src="selected_character.url"
            alt=""
          />
          <img
            v-if="img_url || selected_character != 'null' || selected_plant != 'null'"
            :src="selected_plant.value.url"
            :class="'plants ' + selected_plant.value.class"
            alt=""
          />
        </div>
      </div>

      <!-- <img src="../assets/images/plants01.png" class="plants" alt=""> -->
      <!-- <canvas
      id="canvas"
      style="width: 768px; height: 435px; border-radius: 20px;"
    ></canvas> -->
      <!-- <h2 class="text-center pt-4 sf-bold" style="color: #3C8EFD;">Customize it!</h2>
    <p class="text-center text-grey">Add your own special flair and share it with the world.</p> -->

      <div class="col-auto">
        <hr />
        <h5 class="pt-2">Complete These Sentences</h5>

        <h4 class="text-left">Trailhead taught me</h4>

        <input
          type="text"
          v-model="taught"
          @input="assertMaxChars()"
          class="form-control"
        />
        <p v-if="taught.length >= 140" class="text-danger">
          Maximum 140 characters
        </p>

        <h4 class="pt-3 text-left">Empowering me to</h4>

        <input
          type="text"
          v-model="empower"
          @input="assertMaxEmpowerChars()"
          class="form-control"
        />
        <p v-if="empower.length >= 140" class="text-danger">
          Maximum 140 characters
        </p>

        <p
          v-if="contains_quotes"
          class="pt-2"
          style="color: red; font-weight: bold"
        >
          TIP! No need to include quotations in your text.
        </p>

        <h5 class="pt-4">Add your image or character</h5>

        <div
          class="d-flex pt-1 photo_btns"
          style="align-items: center; justify-content: space-evenly"
        >
          <div>
            <b-button
              style=""
              @click="openUploadModal"
              variant="primary"
              size="lg"
              >Upload Your Photo</b-button
            >
          </div>

          <h2
            class="text-center pt-2 pt-lg-4 sf-bold"
            style="font-size: 22px; color: #3c8efd"
          >
            Or
          </h2>
          <div>
            <b-form-select
              style="min-width: 240px"
              @change="characterSelected"
              class=""
              v-model="selected_character"
              :options="character_options"
            ></b-form-select>
          </div>
        </div>
      </div>

      <div class="col-auto">
        <h5 class="pt-4">Choose a background</h5>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-2"
            v-model="selected_bg"
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
          >
            <b-form-radio
              v-model="selected_bg"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="bg-blue.png"
              >Sky Blue</b-form-radio
            >
            <b-form-radio
              v-model="selected_bg"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="bg-darkblue.png"
              >Periwinkle</b-form-radio
            >
            <b-form-radio
              v-model="selected_bg"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="bg-orange.png"
              >Sunshine</b-form-radio
            >
            <b-form-radio
              v-model="selected_bg"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              value="bg-green.png"
              >Teal</b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>
      </div>

      <!-- <div class="mt-3">Selected: <strong>{{ selected_bg }}</strong></div> -->
      <!-- <div class="row">
      <div class="col-5 mx-auto d-block text-center">
        <b-button @click="openUploadModal" variant="primary" size="lg">Upload Your Photo</b-button>
      </div>
      <div class="col-2">
        <h2 class="text-center pt-4 sf-bold" style="color: #3C8EFD;">Or</h2>
      </div>
      <div class="col-5 mx-auto d-block text-center">
        <b-form-select
          @change="characterSelected"
          class=""
          v-model="selected_character"
          :options="character_options"
        ></b-form-select>
      </div>
    </div> -->
      <!-- <h5 class="pt-4">Personalize your Snapshot</h5> -->

      <div class="col-auto">
        <label class="sr-only" for="inlineFormInputGroup">Name</label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text"><b-icon-person></b-icon-person></div>
          </div>
          <input
            type="text"
            name="name"
            v-model="name"
            class="form-control"
            id="inlineFormInputGroup"
            placeholder="Name"
            @input="assertMaxNameChars()"
          />
        </div>
        <p v-if="name.length >= 25" class="small text-danger">
          Maximum 25 characters
        </p>
      </div>

      <div class="col-auto">
        <label class="sr-only" for="inlineFormInputGroup">Title</label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text"><b-icon-person></b-icon-person></div>
          </div>
          <input
            type="text"
            name="title"
            v-model="title"
            class="form-control"
            id="inlineFormInputGroup"
            placeholder="Title"
            @input="assertMaxTitleChars()"
          />
        </div>
        <p v-if="title.length >= 25" class="small text-danger">
          Maximum 25 characters
        </p>
      </div>

      <div class="col-auto">
        <label class="sr-only" for="inlineFormInputGroup">Company</label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text"><b-icon-person></b-icon-person></div>
          </div>
          <input
            type="text"
            name="company"
            v-model="company"
            class="form-control"
            id="inlineFormInputGroup"
            placeholder="Company (Optional)"
            @input="assertMaxCompanyChars()"
          />
        </div>
        <p v-if="company.length >= 25" class="small text-danger">
          Maximum 25 characters
        </p>
      </div>

      <div class="py-4 pl-3">
        <b-button
          style=""
          @click="openUploadModalBadge"
          variant="info"
          size="md"
          >Upload A Badge (optional)</b-button
        >
      </div>

      <div class="col-auto">
        <label class="sr-only" for="inlineFormInputGroup"
          >Badge Title (optional)</label
        >
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <b-icon-person-badge></b-icon-person-badge>
            </div>
          </div>
          <input
            type="text"
            name="badge_title"
            v-model="badge_title"
            class="form-control"
            id="inlineFormInputGroup"
            placeholder="Badge that helped you (Optional)"
            @input="assertMaxBadgeChars()"
          />
        </div>
        <p v-if="badge_title.length >= 25" class="small text-danger">
          Maximum 25 characters
        </p>
      </div>

      <b-button
        :disabled="submitDisabled"
        class="mt-4"
        @click="downloadFile"
        block
        variant="primary"
        >Download It!</b-button
      >
      <p class="small pt-3">
        Note: The file may automatically save to your Downloads folder. It will
        be called 'my-trailhead.png'.
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

import resize from "vue-resize-directive";
// require('dotenv').config();

export default {
  directives: {
    resize,
  },
  watch: {
    selected_bg() {
      this.selected_plant = this.plant_options[this.plantImage]
    }
  },
  data() {
    return {
      maxLengthInChars: 140,
      max25LengthInChars: 25,
      selected_bg: "bg-blue.png",
      vueCanvas: "",
      rectWidth: 200,
      img_url: "",
      plant_file: "plants-blue.png",
      text: "Trailhead taught me to, empowering me to ",
      name: "",
      title: "",
      company: "",
      downloadable_uri: "",
      submitDisabled: true,
      badge_url: "",
      badge_title: "",

      taught: "",
      empower: "",

      // selected_character: 'https://res.cloudinary.com/da84jf4vw/image/upload/v1614879384/trailhead/characters/appy_2x_cbxd1o.png',
      selected_character: "null",
      selected_character_url: "null",
      selected_character_class: "null",
      selected_plant: "null",
      plant_options: [
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1647914065/trailhead/plants/plants-blue_hgl1ex.png",
            class: "sky-blue",
          },
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1647875524/trailhead/plants/plants-orange_wjfmsn.png",
            class: "sunshine",
          },
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1647875524/trailhead/plants/plants-darkblue_ojumpa.png",
            class: "periwinkle",
          },
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1647875524/trailhead/plants/plants-green_b3fun8.png",
            class: "teal",
          },
        },
      ],
      character_options: [
        { value: null, text: "Pick A Character" },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643986751/trailhead/characters/appy_2022_ayjft5.png",
            class: "appy",
          },
          text: "Appy",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643989933/trailhead/characters/Astro_Trailblazer_hoodie_wave_vybdto.png",
            class: "astro",
          },
          text: "Astro",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1647873574/trailhead/characters/Blaze_jumping_right_xxcggn.png",
            class: "blaze",
          },
          text: "Blaze",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/a_hflip/v1643990720/trailhead/characters/Brandy_armscrossed_ldqay5.png",
            class: "brandy",
          },
          text: "Brandy",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1647874716/trailhead/characters/Cloudy_Trailblazer_left_akqcbh.png",
            class: "cloudy",
          },
          text: "Cloudy",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643990721/trailhead/characters/Codey_forwardfacing_icloudcode_fk9z9e.png",
            class: "codey",
          },
          text: "Codey",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643990721/trailhead/characters/Einstein_eureka_ledge_left_nss3gi.png",
            class: "einstein",
          },
          text: "Einstein",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643990721/trailhead/characters/Hootie_Trailblazer_flying_fzcd0s.png",
            class: "hootie",
          },
          text: "Hootie",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643990722/trailhead/characters/Koa_ball_sitting_D_zeeftm.png",
            class: "koa",
          },
          text: "Koa",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643990722/trailhead/characters/Max_Trailblazer_waving_left_v1_t5spl0.png",
            class: "max",
          },
          text: "Max",
        },
        {
          value: {
            url: "https://res.cloudinary.com/da84jf4vw/image/upload/v1643986860/trailhead/characters/ruth_2022_pzoaxs.png",
            class: "ruth",
          },
          text: "Ruth",
        },
      ],
    };
  },
  computed: {
    plantImage() {
      switch (this.selected_bg) {
        case "bg-blue.png":
          // this.selected_plant = this.plant_options[0];
          // return require("@/assets/images/plants-blue.png");
          return 0
        case "bg-darkblue.png":
          // return require("@/assets/images/plants-darkblue.png");
          return 2
        case "bg-orange.png":
          // return require("@/assets/images/plants-orange.png");
          return 1
        case "bg-green.png":
          // return require("@/assets/images/plants-green.png");
          return 3
        default:
          // return require("@/assets/images/plants-blue.png");
          return 0
      }
      // return require('@/assets/images/' + this.plant_file)
    },
    bgImage() {
      return require("@/assets/images/" + this.selected_bg);
    },
    inlineStyle() {
      return {
        backgroundImage: `url(${this.bgImage})`,
      };
    },
    contains_quotes() {
      if (this.taught.includes('"') || this.empower.includes('"')) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    assertMaxChars: function () {
      if (this.taught.length >= this.maxLengthInChars) {
        this.taught = this.taught.substring(0, this.maxLengthInChars);
      }
    },
    assertMaxEmpowerChars: function () {
      if (this.empower.length >= this.maxLengthInChars) {
        this.empower = this.empower.substring(0, this.maxLengthInChars);
      }
    },
    assertMaxNameChars: function () {
      if (this.name.length >= this.max25LengthInChars) {
        this.name = this.name.substring(0, this.max25LengthInChars);
      }
    },
    assertMaxTitleChars: function () {
      if (this.title.length >= this.max25LengthInChars) {
        this.title = this.title.substring(0, this.max25LengthInChars);
      }
    },
    assertMaxCompanyChars: function () {
      if (this.company.length >= this.max25LengthInChars) {
        this.company = this.company.substring(0, this.max25LengthInChars);
      }
    },
    assertMaxBadgeChars: function () {
      if (this.badge_title.length >= this.max25LengthInChars) {
        this.badge_title = this.badge_title.substring(
          0,
          this.max25LengthInChars
        );
      }
    },
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "da84jf4vw", upload_preset: "wfprou02" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.selected_character = null;
              this.img_url = result.info.secure_url;
              console.log(result.info);
              console.log(this.img_url);
              this.submitDisabled = false;
            }
          }
        )
        .open();
    },
    openUploadModalBadge() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "da84jf4vw", upload_preset: "yi6m1soh" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.badge_url = result.info.secure_url;
            }
          }
        )
        .open();
    },
    characterSelected(e) {
      console.log("character selected");
      console.log(e);
      console.log(this.selected_character);
      this.img_url = null;
      this.selected_plant = this.plant_options[this.plantImage]
      this.submitDisabled = false;
    },
    downloadFile() {
      var preview = document.getElementById("preview");
      var wrapper = this.$refs.scaleablewrapper;

      wrapper.style.transform = "translate(0%, 0%) " + "scale(1)";

      preview.style.transform = "translate(0%, 0%) " + "scale(1)";
      preview.style.left = "0";
      preview.style.top = "0";

      domtoimage
        .toPng(preview, { width: 780 })
        .then((dataUrl) => {
          var img = new Image();
          img.src = dataUrl;
          this.downloadable_uri = dataUrl;
          // document.body.appendChild(img);

          saveAs(dataUrl, "my-trailhead.png");

          let formData = new FormData();
          formData.append("file", dataUrl);
          formData.append("upload_preset", "lc4ndeaa");
          axios
            .post(
              "https://api.cloudinary.com/v1_1/da84jf4vw/image/upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(function () {
              console.log("SUCCESS!!");
              // this.onResize(wrapper)

              var scale = Math.min(
                wrapper.clientWidth / preview.clientWidth,
                wrapper.clientHeight / preview.clientHeight
              );
              wrapper.style.transform =
                "translate(0%, 0%) " + "scale(" + scale + ")";

              preview.style.transform = "translate(-50%, -2%)";
              preview.style.left = "50%";
              // preview.style.top = "50%";
            })
            .catch(function (e) {
              console.log(e);
              console.log("FAILURE!!");
            });
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
    onResize(wrapper) {
      // console.log(wrapper)
      // console.log(this.$refs.scaleablewrapper.clientWidth)
      // console.log(wrapper.clientHeight)
      // console.log(wrapper.clientWidth)
      // console.log(preview.clientWidth)
      // console.log(preview.clientHeight)
      var preview = this.$refs.preview;

      var scale = Math.min(
        wrapper.clientWidth / preview.clientWidth,
        wrapper.clientHeight / preview.clientHeight
      );

      // console.log(scale)
      wrapper.style.transform = "translate(0%, 0%) " + "scale(" + scale + ")";
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  background-color: #e6f6fe;
  height: calc(2.2em + 0.75rem + 2px);
}

h4 {
  font-family: "salesforcesans-bold";
  font-size: 18px;
  color: #030303;
}

h5 {
  font-family: "salesforcesans-regular";
  font-size: 14px;
  color: #205ca1;
}

#photo-container {
  background: white;
  min-height: 500px;
  border-radius: 25px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.15);
  padding: 0;
  // display: none;
}

#mobile-container {
  background: white;
  min-height: 500px;
  border-radius: 25px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.15);
  padding: 2rem;
}

.photo_btns {
  flex-direction: column;
}

@media (min-width: 768px) {
  #photo-container {
    display: block;
  }
  #mobile-container {
    display: none;
  }

  .photo_btns {
    flex-direction: row;
  }
}

@media (min-width: 961px) {
  #photo-container {
    padding: 1rem;
  }
}
.light {
  font-family: "salesforcesans-light";
  font-size: 17px;
}

.text-grey {
  color: #79828d;
}

.sf-bold {
  font-family: "salesforcesans-bold";
}

.trailhead {
  font-family: "trailhead-bold";
  color: #00a1e0;
  font-size: 28px;
}

#preview {
  // background-image: url("https://res.cloudinary.com/da84jf4vw/image/upload/w_768,c_scale/v1614825184/default-bg_hfrukv.png");
  // background-image: url("../assets/images/bg-blue.png");
  background-size: cover;
  display: flex;
  align-items: center;
  width: 768px;
  height: 435px;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -2%);
  transform-origin: center center;

  p {
    font-size: 24px;
    line-height: 28px;
  }

  img {
    // position: relative;
    // top: -172px;
    // left: 496px;
  }

  img.teal {
    max-height: 189px;
    left: -228px;
  }

  img.periwinkle {
    max-width: 480px;
    max-height: 260px;
    left: -315px;
  }

  img.sunshine {
    max-width: 593px;
    left: -383px;
  }

  img.photo_img {
    max-width: 286px;
    margin-left: 35px;
    margin-top: 99px;
    max-height: 350px;
  }
  img.character_img {
    max-width: 200px;
    margin-left: 50px;
    margin-top: 68px;
    max-height: 500px;
  }

  img.appy {
    max-width: 260px;
    margin-left: 10px;
    margin-top: 89px;
  }

  img.astro {
    max-width: 260px;
    margin-left: 10px;
    margin-top: 87px;
  }

  img.blaze {
    max-width: 310px;
    margin-left: -15px;
  }

  img.brandy {
    max-width: 210px;
    margin-left: 40px;
    margin-top: 97px;
  }

  img.cloudy {
    max-width: 225px;
    margin-top: 80px;
    margin-left: 30px;
  }

  img.codey {
    max-width: 420px;
    margin-left: -80px;
    margin-top: 80px;
  }

  img.hootie {
    max-width: 340px;
    margin-left: -50px;
  }

  img.koa {
    max-width: 268px;
    margin-left: 43px;
    margin-top: 146px;
  }

  img.ruth {
    max-width: 440px;
    margin-left: -86px;
    margin-top: 83px;
  }

  img.saasy {
    max-width: 320px;
    margin-left: 0px;
  }
}

// img.plants {
//     position: absolute;
//     max-width: 311px;
//     top: 387px;
//     left: 813px;
// }

img.plants {
  position: relative;
  max-width: 400px;
  // top: 163px;
  align-self: flex-end;
  left: -312px;
  max-height: 149px;
  top: 18px;
}

h5 {
  font-weight: bold;
  margin-bottom: 20px;
}

img.badges {
  position: relative;
  max-width: 81px;
  align-self: flex-start;
  margin-top: 120px;
  // padding-left: 20px;
  // transform: rotate(-4deg);
}
</style>
