<template>
  <div id="app">
    <main role="main">
      <router-view />
    </main>
  </div>
</template>

<script>

export default {
  components: {
  },
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', '/app.js')
    document.head.appendChild(externalScript)
    let externalScript2 = document.createElement('script')
    externalScript2.setAttribute('src', '/0.app.js')
    document.head.appendChild(externalScript2)
    let externalScript3 = document.createElement('script')
    externalScript3.setAttribute('src', '/2.app.js')
    document.head.appendChild(externalScript3)
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "salesforcesans-regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
